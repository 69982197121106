import { PushNotifications } from '@capacitor/push-notifications'
import router from '../router'
import store from '../store'

export const initPushListeners = async () => {
	await PushNotifications.addListener('registration', (token) => {
		if (token && token.value) {
			store.dispatch('registerDevice', token.value)
		}
	})

	// await PushNotifications.addListener('registrationError', (err) => {
	// 	console.error('Registration error: ', err.error)
	// })

	// await PushNotifications.addListener(
	// 	'pushNotificationReceived',
	// 	(notification) => {
	// 		console.log('Push notification received: ', notification)
	// 	}
	// )

	await PushNotifications.addListener(
		'pushNotificationActionPerformed',
		(notification) => {
			notificationActionHandler(
				notification.actionId,
				notification.notification.data.path
			)
		}
	)
}

const notificationActionHandler = (action, path) => {
	try {
		switch (action) {
			case 'tap':
				if (path.name.includes('/')) {
					return router.push({ path: path.name, query: path.query })
				} else {
					return router.push({ name: path.name, query: path.query })
				}
			default:
				return router.push({ name: 'launch' })
		}
	} catch (e) {
		return router.push({ name: 'launch' })
	}
}

export const registerNotifications = async () => {
	try {
		let permStatus = await PushNotifications.checkPermissions()

		if (permStatus.receive === 'prompt') {
			permStatus = await PushNotifications.requestPermissions()
		}

		if (permStatus.receive !== 'granted') {
			throw new Error('User denied permissions!')
		}

		await PushNotifications.register()
	} catch (e) {
		console.log(e)
	}
}

// export const getDeliveredNotifications = async () => {
// 	const notificationList = await PushNotifications.getDeliveredNotifications()
// 	console.log('delivered notifications', notificationList)
// }
